const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://snu32dc44a.execute-api.ap-southeast-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://mg7k8tz8b8.execute-api.ap-southeast-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.2',
    HOSTNAME: 'https://teams-service.staging.newcrest.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.newcrest.forwoodsafety.com',
    WEBSOCKET: 'wss://j12xi7i7gd.execute-api.ap-southeast-2.amazonaws.com/staging'
  },
};

export default config;
